<template>
	<b-card
		:border-variant="color"
		:header-bg-variant="color"
		header-text-variant="white"
		:style="!oldData && apontamentoAutonomo ? { 'border-color': apontamentoAutonomo.motivoParada.cor } : {}"
	>
		<template #header>
			<div :style="!oldData && apontamentoAutonomo ? { 'background-color': apontamentoAutonomo.motivoParada.cor } : {}">
				<h6 class="d-inline-block m-0 text-truncate" :title="nomeMaquina">
					{{ nomeMaquina }}
				</h6>
				<h6
					v-b-tooltip.hover.bottomright="{ title: machineStatus + (offline ? ' - ' + timeOffline : '') + '<br>' + locationText, html: true }"
					class="d-inline-block text-right mb-0 text-truncate"
				>
					<span v-if="!desativada && instalada && !isOperatingNow && temDadosNaDataTurno">
						<UserXIcon v-if="offline" class="align-top mr-1" size="1x"/>
						<AlertTriangleIcon v-if="!offline" class="align-top" size="1x"/>
					</span>
					{{ machineStatus }} | {{ locationText }}
				</h6>
			</div>
		</template>
		<b-overlay :show="loading" rounded="sm" :opacity="1">
			<div v-if="(!offline || oldData || (temDadosNaDataTurno && !isOperatingNow)) && instalada && !desativada">
				<div class="d-flex flex-row justify-content-between">
					<div class="text-center mr-1">
						<label class="mb-0">T/h</label>
						<p class="text-warning" v-if="produtividade">{{ produtividade }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>

					</div>
					<div class="text-center mr-1">
						<label class="mb-0">Prod./Plan.</label>
						<p class="text-warning" v-if="producaoRealETeorica">{{ producaoRealETeorica }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
					<div class="text-center">
						<label class="mb-0">Parada</label>
						<p class="text-warning" v-if="duracaoParada">{{ duracaoParada }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
				</div>

				<div class="d-flex flex-row justify-content-between">
					<div class="text-center mr-1">
						<label class="mb-0">WR (%)</label>
						<p class="text-warning" v-if="wr">{{ wr }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
					<div class="text-center mr-1">
						<label class="mb-0">UR (%)</label>
						<p class="text-warning" v-if="ur">{{ ur }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
					<div class="text-center">
						<label class="mb-0">Qualidade (%)</label>
						<p class="text-warning" v-if="qualidade">{{ qualidade }}</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
				</div>

				<div :class="estadoAlarme ? 'text-center' : 'd-flex flex-row justify-content-between'" style="height: 37px">
					<div class="text-center" :class="estadoAlarme ? 'div-oee' : ''">
						<label class="mb-0">OEE</label>
						<p class="text-success" v-if="oeeTecnico">{{ oeeTecnico }}%</p>
						<p class="text-warning" v-else><b-spinner small></b-spinner></p>
					</div>
					<ECharts autoresize :options="options" :class="estadoAlarme ? 'div-grafico' : ''" style="height: 35px;"/>
					<div v-if="estadoAlarme" class="text-center div-icone">
						<div v-if="nivelAlarme">
							<label v-if="estadoAlarme == 'NA'" class="mb-0 alarmeOK labelsIOLink">Manut. OK</label>
							<label v-else class="mb-0 labelsIOLink"  :class="{
								'hhll': nivelAlarme === 'HH' || nivelAlarme === 'LL',
								'hl': nivelAlarme === 'L' || nivelAlarme === 'H',
							}"
							>Alarme Manut</label>

							<p class="alarmeOK" v-if="estadoAlarme == 'NA'"><CheckCircle-icon class="iconesIOLink"/> </p>
							<p v-if="estadoAlarme == 'OFF'" :class="{
								'hhll': nivelAlarme === 'HH' || nivelAlarme === 'LL',
								'hl': nivelAlarme === 'L' || nivelAlarme === 'H',
							}">
							<AlertTriangle-icon class="iconesIOLink"/> </p>
							<p :class="{
								'hhll': nivelAlarme === 'HH' || nivelAlarme === 'LL',
								'hl': nivelAlarme === 'L' || nivelAlarme === 'H',
							}"
							v-if="estadoAlarme == 'ON'"> <radio-icon class="iconesIOLink"/> </p>
						</div>
						<div v-else>
							<label for=""></label>
							<p class="text-warning"><b-spinner small></b-spinner></p>
						</div>
					</div>
				</div>

				<div class="apontamento-autonomo d-flex align-items-center" v-if="!oldData">
					<div title="Apontado manualmente via App"
						class="label-apont flex-shrink-0">Apont.: </div>
					<div
						class="text-center text-ellipsis text-bold flex-grow-1 min-w-0"
						:title="apontamentoAutonomo ? apontamentoAutonomo.motivoParada.nome : '-'"
					>
						{{ apontamentoAutonomo ? apontamentoAutonomo.motivoParada.nome : "-" }}
					</div>
					<div class="min-w-0 flex-shrink-0" v-if="localizacaoDesatualizada"
						v-b-tooltip.hover.bottomright="{ title: 'Rastreador sem localização recente.' + '<br>' + 'Última atualização: ' + ultimaAtualizacaoLocalizacao, html: true }">
						<GlobeWarning-icon size="14"/>
					</div>
				</div>
			</div>

			<div class="offline-display" v-else>
				<div class="d-flex flex-column justify-content-between">
					<img class="px-3 py-2" src="/icons/map-markers/prensagrey.png"/>
					<div class="mb-2 text-center" v-if="desativada"><b>Desativada</b></div>
					<div class="mb-2 text-center" v-if="!desativada && !instalada"><b>Não Instalada</b></div>
					<div class="mb-2 text-center" v-if="!desativada && instalada">
						Offline - {{ timeOffline }}
						<p class="text-ellipsis" v-if="paradaAtiva" :title="paradaAtiva">
							{{ paradaAtiva }}
						</p>
					</div>
					<div class="apontamento-autonomo d-flex align-items-center">
						<div title="Apontado manualmente via App"
							class="label-apont flex-shrink-0">Apont.:</div>
						<div class="text-center text-ellipsis text-bold flex-grow-1 min-w-0"
							:title="!oldData && apontamentoAutonomo ? apontamentoAutonomo.motivoParada.nome : '-'">
							{{ !oldData && apontamentoAutonomo ? apontamentoAutonomo.motivoParada.nome : "-" }}
						</div>
						<div class="min-w-0 flex-shrink-0" v-if="localizacaoDesatualizada"
							v-b-tooltip.hover.bottomright="{ title: 'Rastreador sem localização recente.' + '<br>' + 'Última atualização: ' + ultimaAtualizacaoLocalizacao, html: true }">
							<GlobeWarning-icon size="14"/>
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
	</b-card>
</template>

<script>
	import { mapState } from "vuex";
	import dayjs from "dayjs";

	import ECharts from "vue-echarts";
	import "echarts/lib/chart/line";
	import baseChartFactory from "../../charts/base.echarts";

	export default {
		props: {
			maquina: {
				type: Object,
				required: true
			},
			oldData: {
				type: Boolean,
				required: true
			},
			loadingLocation: {
				type: Boolean,
				required: true
			},
			offline: {
				type: String
			},
			instalada: {
				type: Boolean
			},
			desativada: {
				type: Boolean
			},
			isOperatingNow: {
				type: Boolean
			},
			ultimoEventoEstado: {
				required: true
			},
			duracaoParadaBruta: {
				required: true
			},
			parada: {
				required: true
			},
			apontamentoAutonomo: {
				required: true
			},
			produtividade: {
				required: true
			},
			location: {
				required: true
			},
			oeeTecnico: {
				required: true
			},
			qualidade: {
				required: true
			},
			wr: {
				required: true
			},
			ur: {
				required: true
			},
			temDadosNaDataTurno: {
				required: true
			},
			chartData: {
				required: true
			},
			estadoAlarme: {
				required: true
			},
			nivelAlarme: {
				required: true
			},
			producaoRealETeorica: {
				required: true
			},
			ultimaAtualizacaoLocalizacao: {
				type: String
			},
			localizacaoDesatualizada: {
				type: Boolean,
				default: false
			}
		},

		components: { ECharts },

		computed: {
			...mapState(["sideBarWidth"]),

			nomeMaquina () {
				return this.maquina.nome || "Carregando...";
			},

			locationText () {
				if (this.oldData)
					return "Dados Históricos";

				if (this.loadingLocation)
					return "Carregando Localização";

				if (this.location && this.location.city)
					return `${this.location.city} - ${this.location.state}`;

				return this.location ? this.location.state : "Sem Localização";
			},

			machineStatus () {
				return this.offline ? "Offline" : "Online";
			},

			color () {
				// Se não forem dados antigos, usa a cor do apontamento autônomo se ele existir
				if (!this.oldData && this.apontamentoAutonomo)
					return "transparent";

				const tipoEstado = this.ultimoEventoEstado && this.ultimoEventoEstado.estado && this.ultimoEventoEstado.estado.tipo_estado;

				if (!this.instalada || this.desativada)
					return "disabled";

				if (!tipoEstado || this.oldData)
					return "dark";

				if (this.offline) {
					if (tipoEstado !== "Parada Programada" && tipoEstado !== "Parada Não Programada")
						return "dark";

					const dataInicial = dayjs(this.ultimoEventoEstado.data_inicial);
					const dataFinal = dayjs(this.ultimoEventoEstado.data_final);
					if (!(dataInicial.isBefore(dayjs()) && dataFinal.isAfter(dayjs())))
						return "dark";
				}

				if (tipoEstado === "Rodando")
					return "success";

				if (tipoEstado === "Predisposto")
					return "primary";

				if (tipoEstado === "Parada Programada")
					return "warning";

				return "danger";
			},

			duracaoParada () {
				if (!this.duracaoParadaBruta && this.duracaoParadaBruta !== 0)
					return "-";

				let s = this.duracaoParadaBruta;
				const h = Math.floor(s / 3600);
				s -= h * 3600;

				const m = Math.floor(s / 60);
				s -= m * 60;

				return `${this.formatTime(h)}:${this.formatTime(m)}:${this.formatTime(Math.floor(s))}`;
			},

			paradaAtiva () {
				if (!this.parada)
					return null;

				const dataInicial = dayjs(this.parada.data_inicial);
				const dataFinal = dayjs(this.parada.data_final);
				if (!(dataInicial.isBefore(dayjs()) && dataFinal.isAfter(dayjs())))
					return null;

				return this.parada.motivoParada && this.parada.motivoParada.nome;
			}
		},

		data () {
			const options = baseChartFactory({
				title: "",
				xAxisZoom: false,
				yAxisZoom: false,
				toolbox: false,
				xAxis: { type: "time" },
				series: [{
					name: "OEE",
					type: "line",
					showSymbol: false,
					step: "end",
					data: [],
					itemStyle: { color: "#009345" },
					areaStyle: { opacity: 0.3 },
					markLine: {
						silent: true,
						symbolSize: 0,
						label: {
							fontSize: 6,
							distance: 0,
							formatter: "{c}%"
						},
						lineStyle: { color: "#333" },
						data: [{ yAxis: 100 }]
					}
				}]
			});

			options.grid = {
				left: 5,
				right: 17,
				top: 6,
				bottom: 5
			};

			options.xAxis.splitLine = { show: false };
			options.xAxis.axisTick = { show: false };

			options.yAxis.min = 0;
			options.yAxis.max = 100;
			options.yAxis.splitLine = { show: false };
			options.yAxis.axisTick = { show: false };
			options.yAxis.minorSplitLine = { show: false };

			options.tooltip.axisPointer.label = {
				fontSize: 8,
				padding: 2,
				formatter: params => {
					if (params.axisDimension === "x")
						return dayjs(params.value).format("HH:mm");

					return Math.round(params.value) + "%";
				}
			};

			options.dataZoom = [{ type: "inside", filterMode: "none" }];

			return {
				options,
				interval: -1,
				timeOffline: "00:00:00",
				loading: true
			};
		},

		mounted () {
			if (this.maquina)
				this.loading = false;

			this.interval = setInterval(this.updateTimeOffline.bind(this), 1000);
		},

		beforeDestroy () {
			clearInterval(this.interval);
		},

		methods: {
			update () {
				this.loading = !this.maquina;
			},

			formatTime (num) {
				return num > 9 ? num : "0" + num;
			},

			updateTimeOffline () {
				if (!this.offline || !this.instalada || this.desativada) {
					this.timeOffline = "00:00:00";
					return;
				}

				let s = dayjs().diff(dayjs(this.offline), "seconds");

				const h = Math.floor(s / 3600);
				s -= h * 3600;

				const m = Math.floor(s / 60);
				s -= m * 60;

				this.timeOffline = `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
			}
		},

		watch: {
			maquina: {
				deep: true,
				handler: "update"
			},

			chartData: {
				deep: true,
				handler () {
					this.options.series[0].data = this.chartData || [];
					this.options.yAxis.max = (this.chartData || []).reduce((m, d) => Math.max(m, d[1]), 100);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		border: 3px solid;
		border-radius: 0.75rem;

		.card-header {
			padding: 0;

			& > div {
				height: 20px;
				padding: 0.1rem 0.5rem;
			}

			h6 {
				font-size: 0.75rem !important;

				&:first-child {
					width: 40%;
				}

				&:last-child {
					width: 60%;
				}
			}
		}

		.card-body {
			padding: 0.1rem 0.5rem;
			font-size: 72%;
		}

		.d-flex > div {
			min-width: 33%;

			& > p {
				font-weight: 600;
				margin: 0;
			}
		}
	}

	.border-dark {
		border-color: #767070 !important;
	}

	.bg-dark {
		background-color: #767070 !important;
	}

	.border-disabled {
		border-color: #000000 !important;
	}

	.bg-disabled {
		background-color: #000000 !important;
	}

	.text-success {
		color: #06B558 !important;
	}

	.border-success {
		border-color: #06B558 !important;
	}

	.bg-success {
		background-color: #06B558 !important;
	}

	.border-primary {
		border-color: #406ADA !important;
	}

	.bg-primary {
		background-color: #406ADA !important;
	}

	.border-warning {
		border-color: #FAAF40 !important;
	}

	.bg-warning {
		background-color: #FAAF40 !important;
	}

	.border-danger {
		border-color: #EC1C24 !important;
	}

	.bg-danger {
		background-color: #EC1C24 !important;
	}

	.div-icone {
		position: relative;
		left: 12px;
		display: inline-block;
		bottom: 5px;
	}

	.div-grafico {
		width: 53%;
		display: inline-block
	}

	.div-oee {
		position: relative;
		min-width: 15%;
		display: inline-block;
		bottom: 5px;
		margin-right: 5px;
	}

	.labelsIOLink {
		font-size: 70%;
		font-weight: bold;
	}

	.iconesIOLink {
		width: 45%;
		padding-bottom: 10px;
	}

	.hhll {
		color: #EC1C24 !important;
	}

	.hl{
		color: #FAAF40 !important;
	}

	.alarmeOK {
		color: #06B558 !important
	}

	.offline-display {
		font-size: 16px;

		img {
			max-height: 40px;
			object-fit: contain;
		}
	}

	.b-overlay-wrap,
	.b-overlay-wrap > div,
	.b-overlay-wrap > div > div.d-flex.flex-column {
		height: 100%;
	}

	.apontamento-autonomo {
		display: grid;
		grid-template-columns: auto 1fr;
		font-size: 14px;
	}

	.min-w-0 {
		min-width: 0% !important;
	}

	.label-apont {
		min-width: 0% !important;
		display: inline-flex;
		padding-right: 5px;
	}
</style>
